<template>
  <div>
    <div class="fixed inset-0 bg-green-300"
         @click="sendTgMessage()"></div>
  </div>
</template>

<script>
import axiosInstance from '@/services/axios';

export default {
  data () {
    return {
      msg: 'Да',
    };
  },
  methods: {
    sendTgMessage () {
      return axiosInstance.post('/api/v1/stats/tgmessage', { text: this.msg })
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>

<style scoped>

</style>
